import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link } from "gatsby"

import Layout from "../../components/layout/layout"
import guardianCounselApi from "../../apis/guardianCounselApi"

const ClassroomManagementPage = () => {
  const { register, handleSubmit, setValue } = useForm()

  const [tempMessage, setTempMessage] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await guardianCounselApi.get("/ClassroomDetails")

      setValue([{ className: data.name }, { videoId: data.videoId }])
    }

    fetchData()
  }, [])

  const formSubmit = async ({ className, videoId }) => {
    await guardianCounselApi.post("/ClassroomManagement", {
      name: className,
      videoId: videoId,
    })

    setTempMessage("Class Details Updated.")

    setTimeout(() => {
      setTempMessage("")
    }, 3500)
  }

  return (
    <>
      <Layout>
        <br />
        <br />

        <h1 style={{ textAlign: "center" }}>Classroom Management</h1>

        {tempMessage && (
          <div style={{ padding: "25px", backgroundColor: "#46bd68", borderRadius: "15px", marginBottom: "25px" }}>
            {tempMessage}
          </div>
        )}

        <form onSubmit={handleSubmit(formSubmit)}>
          <div className="form-group">
            <label htmlFor="className">Class Name</label>
            <input ref={register} type="text" className="form-control" id="className" name="className" />
          </div>

          <div className="form-group">
            <label htmlFor="videoId">YouTube Video ID</label>
            <input ref={register} type="text" className="form-control" id="videoId" name="videoId" />
          </div>

          <button type="submit" className="m-1 btn btn-success">
            Update Class Information
          </button>

          <Link className="btn btn-info" to="/classroom">
            Back to Classroom Dashboard
          </Link>
        </form>
      </Layout>
    </>
  )
}

export default ClassroomManagementPage
